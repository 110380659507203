import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TallyService {
  private _renderer: Renderer2;

  constructor(
    private _rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    this._renderer = this._rendererFactory.createRenderer(null, null);
  }

  loadWidget() {
    const script = this._renderer.createElement('script');
    script.src = 'https://tally.so/widgets/embed.js';
    this._renderer.appendChild(this._document.body, script);
    const userPropertiesScript = this._renderer.createElement('script');
    this._renderer.appendChild(this._document.body, userPropertiesScript);
  }
}
